<template>
  <flight-map :load-items="mapLoadItems"
              :operator-items="['map-mode', 'center', 'undo', 'clear']"
              @map-click="mapClick"
              @operator="operator => $emit('operator', operator)"
              ref="maps"
  >
    <draggable-points :value="pointsValue"
                      source="device"
                      @input="pointInput"
                      @before-input="$emit('before-input')"
                      :read-only="false"
                      :z-index="31"
    />
    <vl-overlay v-if="dialogOpen"
                :position="pointCoordinate"
                :auto-pan="true"
                :auto-pan-animation="{ duration: 300 }"
                positioning="top-center"
    >
      <div class="fms-overlay-container">
        <slot></slot>
      </div>
    </vl-overlay>

  </flight-map>  
</template>

<script>
import { GeographicApiToOl, GeographicOlToApi } from '@/utils/map.js';

import { Icon as MarkerIcon } from '@/components/GoogleMaps/MarkerStyle.js';

import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import DraggablePoints from '@/components/OpenLayers/MultiplePoint.vue';

export default {
  name: 'DeviceMapByOpenlayers',
  components: {
    FlightMap,
    DraggablePoints,
  },
  methods: {
    mapClick(event, featureAndLayerList) {
      let clickEvent = {
        position: GeographicOlToApi(event.coordinate),
      };

      let onDevice = featureAndLayerList.find(featureAndLayer => {
        return featureAndLayer.properties.type === 'point'
          && featureAndLayer.properties.source === 'device';
      });
      if (onDevice) {
        clickEvent.source = 'device';
        this.$emit('map-click', clickEvent);
        return;
      }

      let onFence = featureAndLayerList.find(featureAndLayer => {
        return featureAndLayer.properties.source === 'fence';
      });
      if (onFence) {
        clickEvent.source = 'fence';
        this.$emit('map-click', clickEvent);
        return;
      }

      this.$emit('map-click', clickEvent);
      return;
    },
    pointInput(value) {
      let coordinate = value.list[0].coordinate;
      let data = {
        device: {
          ...this.value,
          ...GeographicOlToApi(coordinate),
        },
      };
      let zones = this.$root.$ol.findFeatureOnCoordinate(coordinate);
      zones = zones.map(z => z.properties);
      data.zones = zones;
      this.$emit('input', data);
    },
    findZonesInPosition(position) {
      let coordinate = GeographicApiToOl(position);
      
      let zones = this.$root.$ol.findFeatureOnCoordinate(coordinate)
      return zones.map(zones => zones.properties);
    },
  },
  computed: {
    deviceTypeApiFormat() {
      let mapper = {
        nest: 'nests',
        camera: 'cameras',
        weather: 'weather_stations',
      };
      return mapper[this.deviceType];
    },
    pointCoordinate() {
      return GeographicApiToOl(this.value);
    },
    pointsValue() {
      return {
        id: this.value.id,
        name: this.value.name,
        list: [{
          icon: this.deviceType,
          iconSet: MarkerIcon,
          coordinate: this.pointCoordinate,
        }],
      };
    },
    mapLoadItems() {
      let items = [
        'fence',
        'no_fly_zone',
        'cameras',
        'weather_stations',
        'nests',
      ];
      items.splice(items.indexOf(this.deviceTypeApiFormat), 1);
      return items;
    },
  },
  props: {
    deviceType: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    dialogOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
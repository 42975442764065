<template>
  <flight-map :load-items="mapLoadItems"
              @operator="operator => $emit('operator', operator)"
              @click="mapClick"
              :flyAreaGuidedClick="mapClick"
              ref="maps"
  >
    <template v-if="markerPosition">
      <!-- Device Marker -->
      <component :is="markerComponent"
                :position="markerPosition"
                :draggable="true"
                @dragstart="$emit('before-input')"
                @dragend="markerInput"
                :z-index="50"
                @click="e => mapClick(e, 'device')"
      />

      <gmap-info-window :options="infoWindowOptions"
                        :position="markerPosition"
                        :opened="dialogOpen"
                        @closeclick="$emit('update:dialogOpen', false)"
      >
        <div class="fms-infowindow-container">
          <!-- slot for infowindow -->
          <slot></slot>
        </div>
      </gmap-info-window>
    </template>
  </flight-map>
</template>

<script>
import FlightMap from "@/components/GoogleMaps/FlightMap.vue";
import Nest from '@/components/GoogleMaps/Nest.js';
import Camera from '@/components/GoogleMaps/Camera.js';
import Weather from '@/components/GoogleMaps/Weather.js';

import { GeographicShorter, GeographicLonger } from '@/utils/map.js';

export default {
  name: 'DeviceMapByGoogleMaps',
  components: {
    FlightMap,
  },
  computed: {
    markerComponent() {
      let mapper = {
        nest: Nest,
        camera: Camera,
        weather: Weather,
      };
      return mapper[this.deviceType];
    },
    markerPosition() {
      if (this.value.latitude == null || this.value.longitude == null) {
        return null;
      }
      return GeographicShorter(this.value);
    },
    infoWindowOptions() {
      let option = {
        pixelOffset: {
          width: 0,
          height: -10,
        },
      };

      switch (this.deviceType) {
        case 'weather':
          option.pixelOffset.height = -30;
      }
      return option;
    },
    mapLoadItems() {
      let items = [
        'fence',
        'no_fly_zone',
        'cameras',
        'weather_stations',
        'nests',
      ];
      switch (this.deviceType) {
        case 'nest':
          items.splice(items.indexOf('nests'), 1);
          return items;
        case 'camera':
          items.splice(items.indexOf('cameras'), 1);
          return items;
        case 'weather':
          items.splice(items.indexOf('weather_stations'), 1);
          return items;
      }
    },
  },
  props: {
    deviceType: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    dialogOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    markerInput(event) {
      let data = {
        device: {
          ...this.value,
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        },
      };
      data.zones = this.$refs.maps.findZonesInPosition(event.latLng);
      this.$emit('input', data);
    },
    mapClick(googleEvent, source) {
      let event = {
        position: {
          latitude: googleEvent.latLng.lat(),
          longitude: googleEvent.latLng.lng(),
        }
      };

      if (source === 'device') {
        event.source = 'device';
        this.$emit('map-click', event);
        return;
      };

      event.source = 'fence';
      this.$emit('map-click', event);
      return;
    },
    findZonesInPosition(position) {
      let latLng = new google.maps.LatLng(
          position.latitude,
          position.longitude,
      );

      return this.$refs.maps.findZonesInPosition(latLng);
    },
  },
}
</script>

<template>
  <admin-fillall-layout>
    <component
      :is="implement"
      ref="maps"
      :device-type="type"
      :value="device"
      :dialog-open.sync="viewMenu"
      @input="input"
      @operator="operator"
      @before-input="saveHistory"
      @map-click="mapClick"
    >
      <!-- infowindow / overlay content -->
      <div class="device-editor">
        <h2>{{ $t(deviceTypeTitle) }}</h2>
        <el-form>
          <el-form-item :label="$t('page.device.dialog.name')">
            <el-input
              size="mini"
              :value="device.name"
              @input="value => textInput({ name: value })"
            />
          </el-form-item>
          <el-form-item :label="$t('page.device.dialog.latitude')">
            <el-input
              size="mini"
              :value="device.latitude"
              @input="value => textInput({ latitude: value })"
              @focus="saveHistory"
              @blur="locationBlur"
            />
          </el-form-item>
          <el-form-item :label="$t('page.device.dialog.longitude')">
            <el-input
              size="mini"
              :value="device.longitude"
              @input="value => textInput({ longitude: value })"
              @focus="saveHistory"
              @blur="locationBlur"
            />
          </el-form-item>
          <!-- <el-form-item :label="$t('map.altitude')" v-if="type==='nest'">
            <el-input size="mini"
                      :value="device.altitude"
                      @focus="saveHistory"
                      @input="value => textInput({ altitude: value })"
            ></el-input>
          </el-form-item> -->
        </el-form>
      </div>
    </component>

    <map-title :group="group.name" />
    <back-button
      :mission-name="device.name"
      @back-click="back"
    />
    <save-button
      :cancel-text="$t('button.cancel')"
      :text="$t('button.save')"
      @save="saveToAPI"
      @cancel="cancel"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';
import { DisMeasure, TmpMeasure } from '@/utils/measure';

import MapTitle from '@/components/Maps/MapTitle.vue';
import BackButton from '@/components/Maps/BackButton.vue';
import SaveButton from '@/components/Maps/SaveButton.vue';

import nestApi from '@/services/api/domain/group/nest';
import cameraApi from '@/services/api/domain/group/camera';
import weatherApi from '@/services/api/domain/group/weather';

import OpenLayersImplement from './DeviceMap-OpenLayers.vue';
import GoogleMapsImplement from './DeviceMap-GoogleMaps.vue';

export default {
  name: 'DeviceMap',
  components: {
    MapTitle,
    SaveButton,
    BackButton,
  },
  data() {
    return {
      history: [],
      historyPosition: 0,
      viewMenu: false,
    };
  },
  computed: {
    implement() {
      switch (this.mapEngine) {
        case 'GoogleMaps':
          return GoogleMapsImplement;
        case 'OpenLayers':
          return OpenLayersImplement;
      }
    },
    type() {
      return this.$route.params.type;
    },
    deviceTypeTitle() {
      const mapper = {
        nest: 'page.device.allNests',
        camera: 'page.device.allCameras',
        weather: 'page.device.allWeathers',
      };
      return mapper[this.type];
    },
    device() {
      switch (this.type) {
        case 'nest':
          return this.nestData;
        case 'camera':
          return this.cameraData;
        case 'weather':
          return this.weatherData;
        default:
          console.error('unknown device');
          return {};
      }
    },
    ...mapGetters({
      group: 'user/group',
      mapEngine: 'user/mapEngine',
      nestMode: 'nest/getMode',
      nestData: 'nest/getNest',
      cameraMode: 'camera/getMode',
      cameraData: 'camera/getCamera',
      weatherMode: 'weather/getMode',
      weatherData: 'weather/getWeather',
    }),
  },
  created() {
  },
  methods: {
    cancel() {
      if (this.history[0]) {
        this.saveToStatus(this.history[0]);
      }
      this.back();
    },
    back() {
      this.$router.push({
        name: 'device',
      });
    },
    textInput(valueObjects) {
      const data = {
        device: {
          ...this.device,
          ...valueObjects,
        },
      };
      this.input(data);
    },
    locationBlur(e) {
      const position = {
        latitude: this.device.latitude,
        longitude: this.device.longitude,
      };
      const data = {
        device: this.device,
      };
      data.zones = this.$refs.maps.findZonesInPosition(position);
      this.input(data);
    },
    input(data) {
      this.openMenu();
      if (typeof (data.device.latitude) === 'string') {
        data.device.latitude = parseFloat(data.device.latitude, 10);
      }
      if (typeof (data.device.longitude) === 'string') {
        data.device.longitude = parseFloat(data.device.longitude, 10);
      }
      if (typeof (data.device.altitude) === 'string') {
        data.device.altitude = parseFloat(data.device.altitude, 10);
      }

      const { zones } = data;
      if (zones == null) {
        this.saveToStatus(data.device);
        return;
      }
      const inFence = zones.find((zone) => zone.source === 'fence');
      const inNoFlyZone = zones.find((zone) => zone.source === 'no_fly_zones' && zone.zoneType === 'no_fly');

      if (inFence == null || inNoFlyZone != null) {
        this.operator('undo');
        return;
      }
      this.saveToStatus(data.device);
    },
    openMenu() {
      this.viewMenu = true;
    },
    closeMenu() {
      this.viewMenu = false;
    },
    mapClick(event) {
      if (event.source === 'device') {
        this.saveHistory();
        this.openMenu();
        return;
      }

      if (event.source === 'fence') {
        const data = {
          ...this.device,
          ...event.position,
        };
        this.saveHistory();
        this.saveToStatus(data);
        this.openMenu();
      }
    },
    operator(name) {
      switch (name) {
        case 'undo':
          if (this.historyPosition <= 0) {
            return;
          }
          this.historyPosition -= 1;
          this.saveToStatus(this.history[this.historyPosition]);
          break;
        case 'clear':
          this.closeMenu();
          this.saveHistory();
          const data = { ...this.device };
          data.latitude = null;
          data.longitude = null;
          this.saveToStatus(data);
          break;
        default:
          console.error('unkonwn operation');
      }
    },
    saveHistory() {
      if (this.history.length > this.historyPosition) {
        const toClean = parseInt(this.history.length) - parseInt(this.historyPosition);
        this.history.splice(this.historyPosition, toClean);
      }
      const index = this.history.push(CopyObject(this.device));
      this.historyPosition = parseInt(index);
    },
    saveToStatus(data) {
      switch (this.type) {
        case 'nest':
          this.setNest(data);
          break;
        case 'camera':
          this.setCamera(data);
          break;
        case 'weather':
          this.setWeather(data);
          break;
      }
    },
    async saveToAPI() {
      let promise;

      switch (this.type) {
        case 'nest': {
          const nestPayload = {
            ...this.nestData,
            min_temperature: this.nestData.temperature[0],
            max_temperature: this.nestData.temperature[1],
          };
          nestPayload.max_temperature = TmpMeasure.real(nestPayload.max_temperature, 2);
          nestPayload.min_temperature = TmpMeasure.real(nestPayload.min_temperature, 2);
          nestPayload.altitude = DisMeasure.real(nestPayload.altitude, 2);

          if (this.nestMode === 'add') {
            promise = nestApi.create(this.group.id, nestPayload);
          } else {
            promise = nestApi.update(this.group.id, this.nestData.id, nestPayload);
          }
          break;
        }
        case 'camera':
          if (this.cameraMode === 'add') {
            promise = cameraApi.create(this.group.id, this.cameraData);
          } else {
            promise = cameraApi.update(this.group.id, this.cameraData.id, this.cameraData);
          }
          break;
        case 'weather':
          if (this.weatherMode === 'add') {
            promise = weatherApi.create(this.group.id, this.weatherData);
          } else {
            promise = weatherApi.update(this.group.id, this.weatherData.id, this.weatherData);
          }
          break;
      }

      const loading = this.$loading({
        lock: true,
      });
      try {
        const result = await promise;
        this.$showSuccess(status.msg);
        this.setNestMode(null);
        this.setCameraMode(null);
        this.setWeatherMode(null);
        this.$router.push({
          name: 'device',
        });
      } catch (error) {
        this.$showFail(error);
        return;
      } finally {
        loading.close();
      }
    },
    ...mapActions({
      setNestMode: 'nest/setMode',
      setNest: 'nest/setNest',
      setCameraMode: 'camera/setMode',
      setCamera: 'camera/setCamera',
      setWeatherMode: 'weather/setMode',
      setWeather: 'weather/setWeather',
    }),
  },
};

</script>
